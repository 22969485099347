@charset "UTF-8";

@import 'lib';
@import 'vendors';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/utilities/position';
@import '~bootstrap/scss/utilities/spacing';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/flex';
@import './burger-menu';

.btn-add-request,
.btn-submit {
    @extend .btn;
    @extend .py-2;
    background-color: $gray-200;
    color: $black;
    text-transform: uppercase;
    width: 100%;

    &:hover {
        background-color: $black;
        border: 1px solid $gray;
        color: $white;
    }
}

html,
body {
    background-color: $white;
    font-family: $font-family-base;
    letter-spacing: .1em;
    min-height: 100vh;
}

main {
    @extend .container-fluid;
    margin-bottom: 4rem;

    @include media-breakpoint-up(lg) {
        padding-left: 48px;
        padding-right: 48px;
    }
}

#overlay, #loading-overlay {
    background: #ffffff;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 2000;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    opacity: .80;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

#loading-overlay .spinner {
    margin: 0 auto;
    height: 64px;
    width: 64px;
    animation: rotate 0.8s infinite linear;
    border: 5px solid firebrick;
    border-right-color: transparent;
    border-radius: 50%;
}

#modal-add-success.show {
    display: flex !important;
    z-index: 2050;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#modal-error.show {
    display: flex !important;
}

.row-no-gutters {
    @extend .row;
    @extend .no-gutters;
}

//SELECT2 customization
.select2-selection__arrow b {
    & {
        @extend .border-0;
    }

    &::after {
        bottom: -10px;
        color: $gray;
        content: '\f078';
        display: inline-block;
        font-family: $font-awesome;
        font-size: 10px;
        -webkit-font-smoothing: antialiased;
        font-style: normal;
        font-variant: normal;
        font-weight: 300;
        left: 0;
        position: absolute;
        text-rendering: auto;
    }
}

.select2-container--open .select2-selection__arrow b::after {
    content: '\f077';
}

.select2-container:not(.select2-container--open):not(.select2-container--focus) {
    .select2-selection__placeholder,
    input::placeholder {
        color: $gray;
    }
}

.select2-container--bootstrap .select2-selection--multiple .select2-selection__choice {
    border-radius: 0;
}

.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
    box-shadow: none;
}

.select2-container--bootstrap.select2-container--open .select2-dropdown {
    border: 1px solid $lavender;
    color: $gray;

    .select2-results__option.select2-results__option--highlighted {
        background-color: $lavender;

        &:not([aria-selected="true"]) {
            color: $gray;
        }
    }
}

.select2-search--dropdown {
    padding: .6rem;

    .select2-search__field {
        padding: .5rem;
    }
}

.select2-selection__clear {
    svg {
        height: 9px;
        width: 9px;

        line {
            stroke-width: 12%;
        }
    }
}

.select2-selection__choice__remove {
    svg {
        height: 7px;
        width: 7px;

        line {
            stroke-width: 20%;
        }
    }
}

.select2-container--bootstrap .select2-selection--multiple .select2-search--inline .select2-search__field {
    margin: -1px .75rem;
    padding: 0;
}

h1 {
    @extend .my-3;
    @extend .text-uppercase;
    color: $black;
}

body {
    .page-title-content {
        @extend .text-center;
        padding: 0 1.5rem .75rem 1.5rem;
        font-family: $font-family-base;
        color: $gray;
        .description .colour,
        .description > * {
            color: $gray !important;
        }
    }

    .flash {
        @extend .text-center;
        @extend .my-2;

        .alert {
            @extend .mx-auto;
            margin-bottom: 24px;
        }

        .alert-error {
            @extend .alert-danger;
        }
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    #header {
        @extend .m-0;
        background-color: $lavender;
        font-size: .7rem;
        position: relative;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 1rem 0;

        .header-content {
            @extend .align-self-center;
            @extend .container-fluid;
            display: flex;
            align-items: center;

            .header-row {
                @extend .row;
                @extend .container-fluid;
                display: contents;

                .header-toggler {
                    @extend .navbar-toggler;
                    @extend .d-lg-none;
                    @extend .position-absolute;
                    border: 0;
                    z-index: 1;
                }

                #banner {
                    @extend .col-lg-4;
                    @extend .col-12;
                    @extend .text-center;
                    align-items: center;
                    display: flex;
                    justify-content: space-around;

                    .website-logo {
                        width: 300px;
                        max-height: 64px;
                        height: 100%;
                        object-fit: scale-down;
                    }
                }

                a {
                    @extend .p-1;
                    color: $gray;

                    &.icon {
                        font-size: 1rem;
                    }

                    &:hover {
                        color: $black;
                        text-decoration: none;
                    }
                }

                .account-menu {
                    display: inline-block;
                    margin-left: .25rem;
                }

                #left-header,
                #right-header {
                    @extend .col-lg-4;
                    @extend .d-lg-block;
                    @extend .d-none;
                    padding: 0 38px;
                    color: $gray;

                    .account-menu-header {
                        line-height: 38px;
                    }
                }

                #right-header {
                    @extend .text-right;

                    .website-locale-container {
                        @extend .d-inline-block;
                        vertical-align: middle;

                        .website-locale-selector {
                            @extend .d-flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;

                            .current-locale-menu {
                                background-color: $lavender;
                                opacity: 1;
                                transition: opacity .5s ease-in-out;
                            }

                            .website-locale-hidden {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                opacity: 0;
                                right: 0;
                                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                    transform: translate(0%, -50%);
                                }

                                .website-locale {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    white-space: nowrap;
                                }

                                .website-locale {
                                    &:not(:last-child):after {
                                        content: '|';
                                    }
                                }
                            }
                        }

                        &:hover .website-locale-hidden {
                            opacity: 1;
                            transition: opacity .5s ease-in-out;
                        }

                        &:hover .current-locale-menu {
                            opacity: 0;
                            transition: opacity .5s ease-in-out;
                        }
                    }

                    .account-signup {
                        margin-left: .25rem;
                    }

                    .account-login,
                    .account-signup {
                        display: none;
                    }

                    .account-menu-dropdown {
                        text-align: center;
                        padding-left: 10px;
                        padding-right: 10px;

                        .separate {
                            @extend .dropdown-divider;

                            &:first-child {
                                display: none;
                            }
                        }

                        .account-details,
                        .admin_list,
                        .account-request,
                        .logout {
                            @extend .dropdown-item;
                        }
                    }
                }

                #left-header {
                    @extend .text-left;
                }
            }
        }
    }

    .claim {
        letter-spacing: 0;

        h2 {
            font-size: 1rem;
        }
    }

    .navbar-menu {
        @extend .container-fluid;
        letter-spacing: .1em;
        text-align: center;

        ul.navbar-menu-inner {
            @extend .d-none;
            @extend .d-lg-block;
            @extend .mx-auto;
            @extend .py-2;
            @extend .mb-0;
            @extend .p-0;

            li {
                display: inline;
                letter-spacing: .1rem;

                @include media-breakpoint-up(md) {
                    &:not(:last-child):after {
                        bottom: 1px;
                        content: ' | ';
                        font-size: .7rem;
                        position: relative;
                    }
                }

                a {
                    @extend .px-2;
                    @extend .text-uppercase;
                    color: $gray-700;
                    font-size: .8rem;
                    padding: 5px;

                    &:hover,
                    &.menu-active {
                        color: $black;
                    }
                }
            }
        }
    }

    #navbar-collapsed {
        @extend .text-center;

        > .close {
            @extend .m-2;
            @extend .p-1;
            font-size: 1.4rem;
            position: absolute;
            right: 0;
        }

        .navbar-collapsed-inner {
            @extend .mb-0;
            @extend .p-3;
            list-style: none;

            li {
                @extend .text-uppercase;
                @extend .py-1;

                a {
                    color: $gray;

                    &:hover:not(.menu-active) {
                        color: $black;
                    }
                }
            }
        }
    }

    .filters-container {
        padding-bottom: 12px;
        padding-top: 12px;
        margin-left: -4px;
        margin-right: -4px;

        .filters {
            @extend .row;
            @extend .no-gutters;
            font-size: .7rem !important;
            justify-content: center;
            text-transform: uppercase;
            @include media-breakpoint-down(md) {
                flex-wrap: nowrap;
                flex-direction: column;
            }

            .filter {
                @extend .px-0;
                @extend .p-sm-1;

                .select2 {
                    width: 100% !important;

                    &.select2-container--disabled {
                        .select2-selection {
                            background-color: inherit;
                            cursor: wait;

                            .select2-search__field {
                                cursor: wait;
                            }
                        }
                    }

                    .select2-selection {
                        border: 1px solid $lavender;
                    }

                    .select2-selection--single {
                        max-height: 42px;
                        min-height: 42px;
                        padding: .75rem 1.5rem .75rem .75rem;
                    }
                }

                select {
                    height: 100%;
                    max-height: 35px;
                    width: 100%;
                }

                input {
                    text-transform: uppercase;
                    min-height: 42px;
                }

                &.multiple {
                    .select2-search__field {
                        padding: 0 .75rem;
                        margin: -1px 0 !important;
                    }

                    .select2 .select2-selection--multiple {
                        min-height: 42px;

                        .select2-selection__choice {
                            margin: .6rem 0 0 .375rem;
                            padding-top: .1rem;
                        }
                    }

                    .select2-selection__clear {
                        display: none;
                    }
                }
            }

            .filter-type,
            .filter-event_type,
            .filter-season,
            .filter-designer,
            .filter-city,
            .filter-tags,
            .filter-events,
            .filter-individuals {
                @extend .col;
            }
        }
    }

    .select2-container--bootstrap .select2-selection {
        font-size: inherit;
    }

    .select2-results {
        font-size: .7rem;

        .select2-results__option {
            @extend .text-uppercase;
        }
    }

    .photo-list,
    .photo-deck {
        &.photo-list:not(.slick) {
            @extend .row;
            @extend .no-gutters;

            .photo-card {
                @extend .col-12;
                @extend .col-sm-6;
                @extend .col-md-4;
                @extend .col-lg-2;
            }
        }

        &.photo-deck {
            @extend .card-deck;
            @extend .mx-auto;
        }

        .photo-list-item {
            @extend .card;
            @extend .col-6;
            @extend .col-md-4;
            @extend .px-1;
            transition: all .1s ease-in;

            .photo-badges {
                @include badges;

                .photo-badge {
                    @include badge;
                }
            }

            .img-container[data-has-embed]::before {
                @include badge;
                top: unset;
                left: unset;
                right: 15px;
                bottom: 15px;
                content: 'VIDEO';
                display: none;
                z-index: 1;
                position: absolute;
            }

            .photo-list-item-link {
                height: 100%; // msie fix
                &:hover {
                    cursor: pointer;
                }

                .photo-list-item-link-img {
                    @extend .card-img-top;
                    border: 1px solid $lavender;
                }

                .photo-list-item-link-details {
                    @extend .card-body;
                    @extend .px-0;
                    @extend .pb-0;
                    @extend .pt-3;
                    color: $gray;
                    margin-bottom: 2rem;
                    font-size: .7rem;

                    .photo-list-item-link-details-designer,
                    .photo-list-item-link-details-group {
                        @extend .card-text;
                        @extend .mb-0;
                    }

                    .photo-list-item-link-details-designer,
                    .photo-list-item-link-details-season {
                        @extend .text-uppercase;
                    }

                }
            }

            &.photo-list-item--x6 {
                @extend .col-lg-2;
                font-size: 0.7rem;
            }

            &.photo-list-item--x4 {
                @extend .col-lg-3;
                font-size: 0.9rem;
            }

            &.photo-list-item--x3 {
                @extend .col-lg-4;
                font-size: 1rem;
            }
        }

        .photo-card {
            @extend .card;
            @extend .p-2;
            @extend .m-0;

            .photo-card-link {
                height: 100%;
                position: relative;
            }

            &:hover {
                .photo-card-img {
                    @include transition(opacity 0.5s ease);
                    opacity: 0.75;
                }
            }

            .photo-card-img {
                @extend .card-img-top;
            }

            .photo-card-sticker {
                display: block;
                position: absolute;
                bottom: 10%;
                width: 100%;
                left: 0;
                right: 0;

                .photo-card-sticker-text {
                    @extend .d-flex;
                    @extend .my-0;
                    @extend .h6;
                    @extend .mx-auto;
                    @extend .p-2;
                    @extend .text-uppercase;
                    background-color: $white;
                    color: $black;
                    font-size: .7rem;
                    min-height: calc(1rem + 2 * 0.75 * 1.2rem);
                    text-align: center;
                    width: 80%;
                    word-break: break-word;
                }
            }
        }
    }

    #nav-pagination {
        @extend .mt-4;

        .pagination-list {
            @extend .pagination;
            @extend .justify-content-center;

            .pagination-list-item {
                @extend .page-item;

                .pagination-list-item-link {
                    @extend .page-link;
                    @extend .h-100;
                    border-color: $lavender;

                    &:hover {
                        background-color: $lavender;
                    }
                }

                &.active .page-link {
                    background-color: $gainsboro;
                }

                &:not(.pagination-previous):not(.pagination-next) {
                    @extend .d-none;
                    @extend .d-sm-block;
                }

                &:not(.disabled) {
                    .page-link {
                        color: $black;
                    }
                }

                &.disabled {
                    cursor: not-allowed;
                }

                svg {
                    position: relative;
                    top: 1px;
                }
            }
        }
    }

    #search-tools {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 12px;

        #count-results {
            @extend .ml-5;
        }
    }
}

.photo-list,
.photo-deck {
    &.photo-list:not(.slick) {
        @extend .row;
        @extend .no-gutters;

        .photo-card {
            @extend .col-12;
            @extend .col-sm-6;
            @extend .col-md-4;
            @extend .col-lg-2;
        }
    }

    &.photo-deck {
        @extend .card-deck;
        @extend .mx-auto;
    }

    .photo-list-item {
        @extend .card;
        @extend .col-6;
        @extend .col-md-4;
        @extend .px-1;
        transition: all .1s ease-in;

        &:hover {
            img {
                @include transition(opacity .5s ease);
                opacity: .75;
            }
        }

        .photo-list-item-link {
            height: 100%; // msie fix
            &:hover {
                cursor: pointer;
            }

            .photo-list-item-link-img {
                @extend .card-img-top;
                border: 1px solid $lavender;
            }

            .photo-list-item-link-details {
                @extend .card-body;
                @extend .px-0;
                @extend .pb-0;
                @extend .pt-3;
                color: $gray;
                margin-bottom: 2rem;
                font-size: .7rem;

                .photo-list-item-link-details-designer,
                .photo-list-item-link-details-group {
                    @extend .card-text;
                    @extend .mb-0;
                }

                .photo-list-item-link-details-designer,
                .photo-list-item-link-details-season {
                    @extend .text-uppercase;
                }

            }
        }

        &.photo-list-item--x6 {
            @extend .col-lg-2;
            font-size: .7rem;
        }

        &.photo-list-item--x4 {
            @extend .col-lg-3;
            font-size: .9rem;
        }

        &.photo-list-item--x3 {
            @extend .col-lg-4;
            font-size: 1rem;
        }
    }

    .photo-card {
        @extend .card;
        @extend .p-2;
        @extend .m-0;

        &:hover {
            .photo-card-img {
                @include transition(opacity .5s ease);
                opacity: .75;
            }
        }

        .photo-card-img {
            @extend .card-img-top;
        }

        .photo-card-sticker {
            bottom: 10%;
            display: flex;
            justify-content: center;
            left: 0;
            position: absolute;
            right: 0;
            width: 100%;

            .photo-card-sticker-text {
                @extend .d-flex;
                @extend .my-0;
                @extend .h6;
                @extend .mx-auto;
                @extend .p-2;
                @extend .text-uppercase;
                align-items: center;
                background-color: $white;
                color: $black;
                font-size: .7rem;
                justify-content: center;
                min-height: calc(1rem + 2 * .75 * 1.2rem);
                text-align: center;
                width: 80%;
                word-break: break-word;
            }
        }
    }
}

#nav-pagination {
    @extend .mt-4;

    .pagination-list {
        @extend .pagination;
        @extend .justify-content-center;

        .pagination-list-item {
            @extend .page-item;

            .pagination-list-item-link {
                @extend .page-link;
                @extend .h-100;
                border-color: $lavender;

                &:hover {
                    background-color: $lavender;
                }
            }

            &.active .page-link {
                background-color: $gainsboro;
            }

            &:not(.pagination-previous):not(.pagination-next) {
                @extend .d-none;
                @extend .d-sm-block;
            }

            &:not(.disabled) {
                .page-link {
                    color: $black;
                }
            }

            &.disabled {
                cursor: not-allowed;
            }

            svg {
                position: relative;
                top: 1px;
            }
        }
    }
}

// ScropTop
.btn-scroll-top {
    @extend .btn;
    @include transition(border .2s ease);
    @include transition(box-shadow .2s ease);
    background-color: $lavender;
    border: 1px solid $lavender;
    border-radius: 50%;
    bottom: 4rem;
    display: none;
    height: 3rem;
    opacity: .8;
    position: fixed;
    right: 3rem;
    width: 3rem;
    z-index: 999;

    @include media-breakpoint-down(md) {
        display: none !important;
    }

    &:hover,
    &:focus {
        border: 1px solid $gray;
        opacity: 1;
    }
}

.alert {
    text-align: center;
}

.account-expiration-counter {
    display: none;
    position: fixed;
    top: 5px;
    right: 5px;
    z-index: 10;

    &.toast.show {
        opacity: 0.6;

        &:hover {
            opacity: 1;
        }
    }
}

footer {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 1.5rem;
    flex-direction: column;
    a:hover {
        transition: .3s;
    }
    a {
        font-size: .7rem;
        color: $gray;
        text-transform: uppercase;
    }
    .powered-by {
        text-align: center;
    }
    .legal-links {
        display: flex;
        justify-content: center;
        width: 100%;
        a {
            margin: 0 1.5rem;
        }
    }
    hr {
        width: 80%;
    }
}
