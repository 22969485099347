@import './variables';

.burger-icon,
.close-menu {
    cursor: pointer;

    svg {
        width: 25px;
        height: 25px;
        fill: $dark;
    }

    &:hover svg {
        fill: $black;
    }
}

.close-menu {
    padding-top: 1.3rem;
}

.burger-menu {
    display: flex;
    flex-direction: column;
    font-size: .9rem;
    background-color: $lavender;
    position: fixed;
    z-index: 100;
    top: 0;
    left: -30%;
    height: 100vh;
    padding: 2rem 3.3rem;
    transition: ease-out .3s;
    border-right: 1px solid;
    width: 30%;
    max-width: 450px;
    &.collapsed {
        left: 0;
        transition: ease-in .3s;
    }

    .header-pages {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
    }
}
