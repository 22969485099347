@font-face {
    font-family: 'GillSansMTStd-Light';
    src: url('../../../fonts/GillSans/309507_0_0.eot');
    src: url('../../../fonts/GillSans/309507_0_0.eot?#iefix') format('embedded-opentype'), url('../../../fonts/GillSans/309507_0_0.woff2') format('woff2'), url('../../../fonts/GillSans/309507_0_0.woff') format('woff'), url('../../../fonts/GillSans/309507_0_0.ttf') format('truetype');
}

@font-face {
    font-family: 'GillSansMTStd-Medium';
    src: url('../../../fonts/GillSans/309507_1_0.eot');
    src: url('../../../fonts/GillSans/309507_1_0.eot?#iefix') format('embedded-opentype'), url('../../../fonts/GillSans/309507_1_0.woff2') format('woff2'), url('../../../fonts/GillSans/309507_1_0.woff') format('woff'), url('../../../fonts/GillSans/309507_1_0.ttf') format('truetype');
}

@font-face {
    font-family: 'GillSansMTStd-Book';
    src: url('../../../fonts/GillSans/309507_2_0.eot');
    src: url('../../../fonts/GillSans/309507_2_0.eot?#iefix') format('embedded-opentype'), url('../../../fonts/GillSans/309507_2_0.woff2') format('woff2'), url('../../../fonts/GillSans/309507_2_0.woff') format('woff'), url('../../../fonts/GillSans/309507_2_0.ttf') format('truetype');
}
